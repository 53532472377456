<template>
  <div id="__nc">
    <div id="nc"></div>
  </div>
</template>
<script>
export default {
  name: "machineCheck",
  data() {
    return {}
  },
  mounted() {
    this.check()
  },
  props: {},
  methods: {
    check(){
      let _that = this
      // 实例化nc
      const nc_token = ["FFFF0N00000000009C79", (new Date()).getTime(), Math.random()].join(':');
      const nc = NoCaptcha.init({
        renderTo: '#nc',
        appkey: 'FFFF0N00000000009C79',
        scene: 'nc_message_h5',
        token: nc_token,
        trans: {"key1": "code200"},
        elementID: ["usernameID"],
        is_Opt: 0,
        language: "cn",
        timeout: 10000,
        retryTimes: 5,
        errorTimes: 5,
        inline: false,
        apimap: {},
        bannerHidden: false,
        initHidden: false,
        callback: function (data) {
          data.nc_token = nc_token
          data.scene = 'nc_message_h5'
          _that.$emit('ncData', data)
        },
        error: function (s) {}
      });
      NoCaptcha.setEnabled(true);
      nc.reset();//请务必确保这里调用一次reset()方法
      NoCaptcha.upLang('cn', {
        'LOADING':"加载中...",//加载
        'SLIDER_LABEL': "请向右滑动验证",//等待滑动
        'CHECK_Y':"验证通过",//通过
        'ERROR_TITLE':"非常抱歉，这出错了...",//拦截
        'CHECK_N':"验证未通过", //准备唤醒二次验证
        'OVERLAY_INFORM':"经检测你当前操作环境存在风险，请输入验证码",//二次验证
        'TIPS_TITLE':"验证码错误，请重新输入"//验证码输错时的提示
      });
    },
    childMethod() {
      this.$parent.reSendCode();
    }
  }
}
</script>

<style lang="scss" type="text/css">
._nc {
  margin-bottom: 24px;
  font-size: 14px;
}
._nc .stage1 {
  width: 635px;
  height: 46px;
  padding: 0;
}
._nc .stage1 .slider {
  width: 635px;
  height: 46px;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  background-color: #ddd;
  left: 0;
  right: 0;
}
._nc .stage1 .track div,
._nc .stage1 .label {
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  border-radius: 0;
  color: #fff;
}
._nc .stage1 .button {
  height: 46px;
  line-height: 46px;
}
._nc .stage1 .bg-green {
  background-color: #78c430;
}
._nc .stage1 .bg-red {
  background-color: #78c430;
}
</style>
