<template>
  <cz-collection :class="customClass" :id="elementId" find-route-name="Root" :include-route-name-list="includeRouteNameList">
    <section class="score-search-container">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item class="search-input" prop="numberValue">
          <p>证件号码</p>
          <el-input placeholder="请输入证件号码" v-model="ruleForm.numberValue"></el-input>
        </el-form-item>
        <el-form-item class="search-input search-input-captcha" prop="captchaCodeValue">
          <p>验证码</p>
          <el-input placeholder="请输入验证码" v-model="ruleForm.captchaCodeValue"></el-input>
          <fox-captcha v-if="machineCheckShow" type="digital" :height="46" ref="foxCaptcha"></fox-captcha>
        </el-form-item>
        <div class="search-button">
          <el-button type="primary" @click="reSendCode()">查询</el-button>
        </div>
      </el-form>
    </section>
    <!-- 结果 -->
    <section v-if="isResultFlag" class="score-search-result-container">
      <p class="img-tip">下载请点击右键图片另存为</p>
      <div class="result-race-image">
        <img v-for="(item,index) in tableData" :key="index" :src="'https://img.shuzixindong.com'+item" alt="">
      </div>
    </section>
  </cz-collection>
</template>

<script type="text/ecmascript-6">
import machineCheck  from './components/machineCheck'
import SearchExtends from '../search-extends.js'
import czApi         from '@/api/cz'
import GlobalConfig  from '@/plugins/globalConfig.js'
import Global        from '@/mixins/global.js'
import FoxCaptcha    from 'fox-captcha'

export default {
  extends   : SearchExtends,
  mixins    : [GlobalConfig, Global],
  components: { machineCheck, FoxCaptcha },
  name      : 'ScoreSearch',
  props     : {
    className: { type: String, default: '' },
  },
  data() {
    return {
      ruleForm        : {
        numberValue: null,//证件号码
        // numberValue     : '362226197606070020',//证件号码
        captchaCodeValue: null,//验证码
      },
      machineCheckShow: true,
      isResultFlag    : false,
      nc_DataArr      : {},
      tableData       : {},
      rules           : {
        numberValue     : [
          { required: true, message: '请填写证件号码', trigger: 'blur' },
        ],
        captchaCodeValue: [
          { required: true, message: '请填写验证码', trigger: 'blur' },
        ],
      },
    }
  },
  watch   : {},
  computed: {
    elementId() {return `ScoreSearch_${this._uid}`},
    customClass() {return ['ScoreSearch', this.className]},
  },
  methods : {
    // 滑块验证成功
    reSendCode() {
      this.$refs['ruleForm'].validate((valid) => {
        let that = this
        if (valid) {
          if (this.$refs.foxCaptcha.validate(this.ruleForm.captchaCodeValue)) {
            czApi.changzhengScoreCenterApiRaceScoreUserGetScoreCertPathByParam({ cardNum: this.ruleForm.numberValue, raceId: this.$store.getters.raceId }).then((res) => {
              // czApi.changzhengScoreCenterApiRaceScoreUserGetScoreCertPathByParam({ cardNum: this.ruleForm.numberValue, raceId: 1000026026 }).then((res) => {
              console.log(res)
              if (res.data && res.data.length > 0) {
                this.tableData    = res.data
                this.isResultFlag = true
                this.$message({ showClose: true, message: '成绩查询成功！', type: 'success' })
                this.slideToScroll()
              }
              else {
                this.$alert('未查询到您的成绩，请稍后再试', '暂无数据', {
                  confirmButtonText: '确定',
                  callback         : action => { },
                })
              }
            }).catch(err => {
              console.log(err)
            })
          }
          else {
            this.$alert('验证码校验失败', '提示', {
              confirmButtonText: '确定',
              callback         : action => { },
            })
          }
        }
        else {
          return false
        }
      })

    },
    slideToScroll() {
      // document.getElementById('resultWeb').scrollIntoView({ behavior: 'smooth' })
      // document.documentElement.scrollTop = 600
      let timer = null
      timer     = setInterval(() => {
        if (document.documentElement.scrollTop < 550) {
          document.documentElement.scrollTop += 6
        }
        else {
          clearInterval(timer)
        }
      }, 0)
    },
  },
  // filters : {
  //   // https://cn.vuejs.org/v2/guide/filters.html
  //   // 使用方法: {{item.title | toUp}}
  //   capitalize: function (value) {
  //     if (!value) {return ''}
  //     value = value.toString()
  //     return value.charAt(0).toUpperCase() + value.slice(1)
  //   },
  // },
  //  directives: {
  //    // 使用方法: https://cn.vuejs.org/v2/guide/custom-directive.html#ad
  //  },
  // beforeRouteEnter(to, from, next) {
  //   // https://router.vuejs.org/zh/guide/advanced/navigation-guards.html
  //   // 在渲染该组件的对应路由被 confirm 前调用
  //   // 不！能！获取组件实例 `this`
  //   // 因为当守卫执行前，组件实例还没被创建
  //   next(vm => {
  //     console.log(vm)
  //   })
  // },
  // beforeRouteUpdate(to, from, next) {
  //   // https://router.vuejs.org/zh/guide/advanced/navigation-guards.html
  //   // 组件被复用的时候调用, 比如 /home/:id 这种路由 传入的参数不同的时候 会调用beforeRouteUpdate
  //   // 在当前路由改变，但是该组件被复用时调用
  //   // 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
  //   // 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
  //   // 可以访问组件实例 `this`
  //   next()
  // },
  // beforeRouteLeave(to, from, next) {
  //   // https://router.vuejs.org/zh/guide/advanced/navigation-guards.html
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  //   let leave = confirm('还未保存,您确定要离开吗?')
  //   if (leave) {next()}
  //   else {next(false)}
  //   // next()
  // },
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {},
}
</script>

<!--cnpm i -D less less-loader stylus stylus-loader sass sass-loader-->
<!--<style scoped lang="less" rel="stylesheet/less">-->
<!--<style scoped lang="stylus" rel="stylesheet/stylus">-->
<!--// { parser: "babylon" } is deprecated; we now treat it as { parser: "babel" }.-->
<!--// npm i vue-loader@14 来修复-->
<style scoped lang="scss" rel="stylesheet/scss">
// score-search
// @import "ScoreSearch.scss"
.ScoreSearch {
  background : #F1F1F1;
  padding    : 0 100px 70px;

  .score-search-container {
    width          : 1200px;
    margin         : 0 auto;
    background     : #ffffff;
    box-sizing     : border-box;
    padding        : 100px 0;
    display        : flex;
    flex-direction : column;
    align-items    : center;

    ::v-deep .search-input .el-form-item__content {
      display         : flex;
      justify-content : center;
      align-items     : center;

      p {
        white-space  : nowrap;
        font-size    : 16px;
        font-weight  : 400;
        color        : #65697E;
        line-height  : 22px;
        margin-right : 12px;
      }
    }

    ::v-deep .search-input .el-input .el-input__inner {
      width         : 541px;
      height        : 46px;
      background    : #FFFFFF;
      border-radius : 2px;
      border        : 1px solid #E6EBF7;
    }

    ::v-deep .search-input .el-form-item__error {
      margin-left : 78px;
    }

    .search-input-captcha {
      .el-input {
        margin : 0 22px 0 16px;
      }

      ::v-deep .el-input .el-input__inner {
        width : 415px;
      }
    }

    .search-button {
      margin-top : 30px;
      text-align : center;

      .el-button {
        width         : 120px;
        height        : 44px;
        background    : #CF000D;
        border-radius : 2px;
      }
    }
  }

  .score-search-result-container {
    background : #ffffff;
    overflow   : hidden;
    padding    : 0 30px 30px;

    .result-race-detail-box {
      width         : 100%;
      //height        : 499px;
      background    : #F9F9F9;
      border-radius : 6px;
      padding       : 60px 160px;
      box-sizing    : border-box;

      h2 {
        font-size   : 26px;
        font-weight : 500;
        color       : #181921;
        line-height : 37px;
        text-align  : center;
        margin      : 0 0 30px 0;
      }

      .detail-list-box {
        display         : flex;
        justify-content : space-between;
        flex-wrap       : wrap;

        .detail-item {
          display     : flex;
          align-items : center;
          margin-top  : 30px;

          p {
            margin : 0;
          }

          .title {
            width        : 80px;
            font-size    : 16px;
            font-weight  : 400;
            color        : #65697E;
            line-height  : 22px;
            margin-right : 30px;
          }

          .result {
            width       : 280px;
            font-size   : 16px;
            font-weight : 400;
            color       : #14151E;
            line-height : 22px;
          }
        }
      }

    }

    .img-tip {
      font-size   : 16px;
      font-weight : 400;
      color       : #65697E;
      line-height : 22px;
      margin      : 30px 0;
      text-align  : right;
    }

    .result-race-image {
      img {
        width : 100%;
      }
    }
  }
}
</style>
